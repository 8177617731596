<template>
  <v-list-group :prepend-icon="item.icon" color="primary" :subgroup="subGroup" fluid>
    <template v-slot:activator="{ props }">
      <v-list-item v-bind="props">
        <v-list-item-title v-text="item.title"/>
      </v-list-item>
    </template>
    <template v-for="(child, i) in children" :key="i">
      <BaseItemSubGroup v-if="child.children" :group="`sub-group-${i}`" :item="child" class="second-dd"/>
      <BaseItem v-else :item="child"/>
    </template>
  </v-list-group>
</template>

<script>
// Utilities
import kebabCase from 'lodash/kebabCase';
import BaseItemSubGroup from "./BaseItemSubGroup.vue";
import BaseItem from "./BaseItem.vue";

export default {
  name: 'BaseItemGroup',
  components: {BaseItemSubGroup, BaseItem},
  props: {
    item: {
      type: Object,
      default: () => ({
        avatar: undefined,
        group: undefined,
        title: undefined,
        children: [],
      }),
    },
    subGroup: {
      type: Boolean,
      default: false,
    },
    text: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    children() {
      return this.item.children.map(item => ({
        ...item,
        to: !item.to ? undefined : `${item.to}`,
      }));
    },
    group() {
      return this.genGroup(this.item.children);
    },
  },
  methods: {
    genGroup(children) {
      return children
          .filter(item => item.to)
          .map(item => {
            const parent = item.group || this.item.group;
            let group = `${parent}/${kebabCase(item.to)}`;
            if (item.children) {
              group = `${group}|${this.genGroup(item.children)}`;
            }
            return group;
          }).join('|');
    },
  },
}
</script>
<style scoped>
.expansion-panels {
  --v-expansion-panel-margin: 8px;
}
.expansion-panel {
  box-shadow: none; /* Removes the shadow */
}
</style>