export default {
    // modules
    brands: 'mdi-creative-commons',
    companies: 'mdi-office-building',
    contacts: 'mdi-contacts-outline',
    conformitymarks: 'mdi-marker-check',
    dashboard: 'mdi-monitor-dashboard',
    distributionpanels: 'mdi-light-switch-off',
    distributiondevicefunctions: 'mdi-monitor-dashboard',
    employees: 'mdi-account',
    fields: 'mdi-format-letter-matches',
    generaldefects: '',
    inspectioncodes: 'mdi-ab-testing',
    inspectioncertificates: 'mdi-certificate',
    inspectioninstitutes: 'mdi-office-building',
    inspectioninstitutecertifications: 'mdi-city',
    inspectionquestions: '',
    inspectionanswers: '',
    inspectionzones: '',
    ipindications: 'mdi-cable-data',
    iptypes: 'mdi-cable-data',
    linevoltage: 'mdi-flash-triangle-outline',
    notes: 'mdi-note',
    profile: 'mdi-account',
    people: 'mdi-human-male-female',
    permissions: 'mdi-security',
    roles: 'mdi-account-group',
    search: 'mdi-folder-search',
    settings: 'mdi-cogs',
    simpledefects: '',
    simpleinspections: 'mdi-account-hard-hat',
    simpleinspectionitems: '',
    simpleequipmenttypes: 'mdi-ticket-confirmation-outline',
    simpleprotectionmethods: 'mdi-shield-check-outline',
    tags: 'mdi-tag',
    templates: 'mdi-file-document-edit',
    tokens: 'mdi-account-key',
    translations: 'mdi-translate',
    workflows: 'mdi-transit-connection-variant',
    users: 'mdi-account',
    zonetypes: 'mdi-select-multiple-marker',
}