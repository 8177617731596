<template>
  <v-row>
    <v-col>
      <v-card :loading="loading" :disabled="loading">
        <v-toolbar flat color="white" dense>
          <!--SEARCH-->
          <v-text-field
              class="ml-5 mt-3"
              color="primary"
              variant="underlined"
              density="compact"
              bg-color="white"
              v-model="search"
              v-debounce:230ms.lock="launchSearch"
              @click:clear="clearSearch"
              :label="$store.getters.translate('search')"
              single-line
              hide-details
              clearable
              append-inner-icon="mdi-magnify"/>
          <v-select
              color="primary"
              variant="underlined"
              class="ml-10 mt-3"
              v-model="options.search_column"
              :items="search_fields"
              item-value="value"
              item-title="text"
              clearable
              @update:model-value="launchSearch"/>
          <!--EXACT SEARCH-->
          <v-switch color="primary" size="x-small" class="ml-10 mt-7" :label="$store.getters.translate('exact_search')" v-model="options.exact_search"/>
          <!--RIGHT PART START (BUTTONS)-->
          <v-spacer/>
          <!--EXCEL EXPORT-->
          <download-excel v-if="records.length > 0" style="font-size: 12px; text-transform: uppercase; cursor: pointer;" :data="records" :name="page.name + '.xls'">
            {{ $store.getters.translate("export_to_excel") }}
            <v-icon color="primary" size="large" class="ml-2" icon="mdi-file-excel"/>
          </download-excel>
          <!--DELETE-->
          <v-btn :disabled="loading" :loading="loading" v-if="selected.length > 0 && canDelete" @click.stop="bulkArchive" size="small">
            {{ $store.getters.translate("delete") }} {{ selected.length }} {{ $store.getters.translate(page.name) }}
            <v-icon color="red" size="large" class="ml-2" icon="mdi-trash-can"/>
          </v-btn>
          <!--CREATE-->
          <v-btn :disabled="loading" :loading="loading" v-if="base_table_options.create && canCreate" @click.stop="$emit('create', true)" size="small">
            {{ $store.getters.translate("create") }}
            <v-icon color="primary" size="large" class="ml-2" icon="mdi-plus"/>
          </v-btn>
        </v-toolbar>
        <v-data-table
            @toggle-select-all="() => { this.selected = !this.selected.length ? this.getRecords : []; }"
            :headers="fields"
            :items="getRecords"
            :loading="loading"
            class="elevation-1"
            item-value="id"
            selectable-key="id"
            return-object
            :show-select="!base_table_options.no_select"
            v-model="selected"
            v-bind="footer_props"
            v-model:items-per-page="options.itemsPerPage"
            v-model:page="options.page"
            @update:currentItems="current = $event">
          <template v-if="!base_table_options.no_tags" #top>
            <v-card flat elevation="0">
              <v-card-text>
                <base-tags :modelValue="tags" :modal_name="page.name" @change="searchByTags"/>
              </v-card-text>
            </v-card>
          </template>
          <!--USUAL TABLE FOR ALL MODELS-->
          <template #body="{ items }">
            <tr v-for="item in items" :key="item.id" @click.ctrl="handleCtrlClick(item)">
              <td v-if="page.name !== 'roles'">
                <v-checkbox color="primary" v-model="selected" :key="item.id" :value="item" @click.shift="handleShiftClick(item)"/>
              </td>
              <td v-for="(key, index) in Object.keys(response_fields)" :key="index" @click.exact="$emit('edit', item)">
                <!--CHECKBOXES/BOOLEAN VALUES-->
                <template v-if="response_fields[key] === 'boolean'">
                  <template v-if="item[key] === '1' || item[key] === 1 || item[key] === 'true' || item[key] === true">1</template>
                  <template v-else>0</template>
                </template>
                <!--DATES-->
                <template v-else-if="response_fields[key] === 'date' && item[key] && (key === 'date_of_birth' || key === 'created_at' || key === 'updated_at')">
                  {{ moment(item[key], "YYYY-MM-DD").format("DD-MM-YYYY") }}
                </template>
                <!--DROPDOWNS-->
                <template v-else-if="(response_fields[key] === 'select_single' || response_fields[key] === 'select_multiple') && item[key]">
                  <template v-if="Array.isArray(item[key])">
                    <template v-if="item[key].length > 1">
                      <v-chip style="height: auto;" class="mr-2" size="x-small" v-for="elem in item[key]" :key="elem">
                        {{ elem }}
                      </v-chip>
                    </template>
                    <template v-else>
                      {{ $lodash.truncate($store.getters.translate(item[key][0]), { length: 40 }) }}
                    </template>
                  </template>
                  <template v-else>
                    {{ $lodash.truncate($store.getters.translate(item[key]), { length: 40 }) }}
                  </template>
                </template>
                <!--LINKS-->
                <template v-else-if="response_fields[key] === 'link'">
                  <a>
                    <v-avatar v-if="key === 'name' && base_table_options.avatar" class="mr-1" size="20">
                      <v-img v-if="item.avatar" style="border: 1px" :src="item.avatar"/>
                      <v-img v-else-if="item.person && item.person.avatar" style="border: 1px" :src="item.person.avatar"/>
                      <v-img v-else style="border: 1px" src="../../../assets/images/no-user.jpg"/>
                    </v-avatar>
                    {{ $lodash.truncate($store.getters.translate(item[key]), { length: 50 }) }}
                  </a>
                </template>
                <!--ID FIELDS-->
                <template v-else-if="key === 'id' || key === 'internal_id'">
                  <a>{{ item[key] }}</a>
                </template>
                <!--TEXT FIELDS-->
                <template v-else>
                  {{ $lodash.truncate($store.getters.translate(item[key]), { length: 40 }) }}
                </template>
              </td>
              <!--ACTION BUTTONS-->
              <td style="text-align: right">
                <v-icon v-if="base_table_options.copy && canCreate" @click="makeCopy(item)" size="small" class="mr-2" icon="mdi-content-copy"/>
                <v-icon v-if="canEdit" @click="$emit('edit', item)" size="small" class="mr-2" icon="mdi-pencil"/>
                <v-icon v-if="canDelete" @click="deleteRecord(item.id)" color="red" size="small" class="mr-2" icon="mdi-trash-can"/>
              </td>
            </tr>
            <tr v-if="getRecords.length === 0">
              <td class="text-center text-grey" :colspan="fields.length + 1">
                <span v-if="!loading">{{ $store.getters.translate("nothing_found") }}</span>
                <span v-else>{{ $store.getters.translate("loading") }}</span>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import lodash from "lodash";
import helpFunctions from "../../plugins/helpFunctions.js";
import { useAbility } from '@casl/vue';

export default {
  props: ["page"],
  setup() {
    const { can } = useAbility()
    return { can }
  },
  data() {
    return {
      loading: false,
      selected: [],
      all_records: [],
      records: [],
      options: {},
      footer_props: helpFunctions.footer_props,
      base_table_options: {},
      search: "",
      original_fields: {},
      response_fields: {},
      search_fields: [],
      fields: [],
      tags: [],
      current: [],
    };
  },
  created() {
    if(helpFunctions.getLocalStorageValue(this.page.name + "_options")) {
      this.options = helpFunctions.getLocalStorageValue(this.page.name + "_options");
    }
    else {
      this.options = helpFunctions.table_options;
    }
    if(helpFunctions.getLocalStorageValue(this.page.name + "_search")) {
      this.search = helpFunctions.getLocalStorageValue(this.page.name + "_search");
    }
    if(helpFunctions.getLocalStorageValue(this.page.name + "_tags")) {
      this.tags = helpFunctions.getLocalStorageValue(this.page.name + "_tags");
    }
    this.base_table_options = helpFunctions.base_table_options[this.page.name];
  },
  methods: {
    load: lodash.debounce(function () {
      this.run();
    }, 100),
    run() {
      this.records = [];
      this.all_records = [];
      this.loading = true;
      this.$http
          .get(this.$store.getters.appUrl + "v2/" + this.page.name + "?source=site")
          .then((response) => {
            this.loading = false;
            this.all_records = response.data.data;
            this.original_fields = helpFunctions.replaceIDwithNameBaseTable(response.data.fields);
            this.search_fields = [];
            this.search_fields.push({
              text: this.$store.getters.translate('all_fields'),
              value: 'all',
            });
            if (this.search && this.search.length > 2) {
              this.response_fields["search_score"] = "text_field";
            }
            Object.entries(this.original_fields).forEach(([key, value]) => {
              this.response_fields[key] = value;
              if(key !== '' && key !== 'sort_order' && key !== 'updated_at' && key !== 'created_at' && key !== 'tags') {
                this.search_fields.push({
                  text: this.$store.getters.translate(key),
                  value: key,
                });
              }
            });
            this.fields = this.composeFields(this.response_fields);
            this.showRecords();
          }).catch((error) => {
            if (this.$store.getters.isLoggedIn) {
              this.$toast.error(error.message);
            }
            this.loading = false;
          });

    },
    composeFields(fields) {
      let response = [];
      let field_names = Object.keys(fields);
      for (let i = 0; i < field_names.length; i++) {
        response.push({
          title: this.$store.getters.translate(field_names[i]),
          key: field_names[i],
          align: "start",
          fixed: true,
          sortable: true,
          value: field_names[i],
        });
      }
      response.push({
        title: this.$store.getters.translate("actions"),
        sortable: false,
        align: "end",
        fixed: true,
        value: "actions",
      });
      return response;
    },
    showRecords() {
      var sorted_array = [];
      if (this.tags.length > 0) {
        sorted_array = helpFunctions.sortByTagsBase(this.all_records, this.tags);
        if (this.search && this.search.length > 2) {
          sorted_array = helpFunctions.searchInSortedArray(sorted_array, this.response_fields, this.search, this.options.search_column, this.options.exact_search);
        }
      } else if (this.search && this.search.length > 2) {
        sorted_array = helpFunctions.searchInSortedArray(this.all_records, this.response_fields, this.search, this.options.search_column, this.options.exact_search);
      } else {
        sorted_array = this.all_records;
      }
      this.records = sorted_array;
    },
    clearSearch() {
      this.search = "";
      if (this.all_records && this.all_records.length > 0) {
        delete this.response_fields["search_score"];
        this.fields = this.composeFields(this.response_fields);
        this.showRecords();
      } else {
        this.load();
      }
    },
    launchSearch() {
      if (this.all_records && this.all_records.length > 0) {
        if (this.search && this.search.length > 2) {
          this.response_fields = {};
          this.response_fields["search_score"] = "text_field";
          Object.entries(this.original_fields).forEach(([key, value]) => {
            this.response_fields[key] = value;
          });
        } else {
          delete this.response_fields["search_score"];
        }
        this.fields = this.composeFields(this.response_fields);
        this.showRecords();
      } else {
        this.load();
      }
    },
    handleCtrlClick(item) {
      if(this.selected.find((el) => el.id === item.id)) {
        this.selected = this.selected.filter((el) => el.id !== item.id);
      }
      else {
        this.selected.push(item);
      }

    },
    handleShiftClick(item) {
        if (this.selected.length === 1 || this.selected.length === 2) {
          const IDs = [this.selected[1]?.id ?? item.id, this.selected[0].id];    // @click.shift is triggered before v-model="selected" updates, so we need to form a list of IDs from the item.
          let start = this.current.findIndex(el => el.raw.id === Math.min(...IDs));       // getting the starting element index
          let end = this.current.findIndex(el => el.raw.id === Math.max(...IDs));       //  end element index
          if(start > end) {
            let tmp = end;
            end = start;
            start = tmp;
          }
          for (let i = start; i < end; i++) {
            if (!this.selected.find((el) => el.id === this.current[i].key)) {
              this.selected.push(this.current[i].raw);
            }
          }
        }
    },
    searchByTags(data) {
      this.tags = data;
      helpFunctions.setLocalStorageValue(this.page.name + "_tags", this.tags);
      if (this.all_records && this.all_records.length > 0) {
        this.showRecords();
      } else {
        this.load();
      }
    },
    bulkArchive() {
      this.$confirm(this.$store.getters.translate("delete_confirmation")).then((res) => {
        if (res) {
          this.loading = true;
          this.$http
              .post(this.$store.getters.appUrl + "v2/bulk/remove", { model: this.page.model, ids: this.$lodash.map(this.selected, "id") })
              .then(() => {
                this.loading = false;
                this.$toast.success(this.$store.getters.translate("successfully_archive"));
                this.selected = [];
                this.load();
              }).catch((error) => {
                if (this.$store.getters.isLoggedIn) {
                  this.$toast.error(error.message);
                }
                this.loading = false;
              });
        }
      });
    },
    deleteRecord(id) {
      this.$confirm(this.$store.getters.translate("delete_confirmation")).then((res) => {
        if (res) {
          this.deleteAction(id);
        }
      });
    },
    async deleteAction(id) {
      this.loading = true;
      this.$http
          .delete(this.$store.getters.appUrl + "v2/" + this.page.name + "/" + id)
          .then(() => {
            this.loading = false;
            this.load();
          }).catch((error) => {
            if (this.$store.getters.isLoggedIn) {
              this.$toast.error(error.message);
            }
            this.loading = false;
          });
    },
    makeCopy(item) {
      this.$confirm(this.$store.getters.translate("are_you_sure")).then((res) => {
        if (res) {
          this.loading = true;
          item.model = this.page.model;
          this.$http
              .post(this.$store.getters.appUrl + "v2/bulk/copy", item)
              .then(() => {
                this.loading = false;
                this.$toast.success(this.$store.getters.translate("success"));
                this.selected = [];
                this.load();
              }).catch((error) => {
                if (this.$store.getters.isLoggedIn) {
                  this.$toast.error(error.message);
                }
                this.loading = false;
              });
        }
      });
    },
  },
  computed: {
    getRecords() {
      return this.records;
    },
    canCreate() {
      return this.can("create", this.page.model);
    },
    canEdit() {
      return this.can("edit", this.page.model);
    },
    canDelete() {
      return this.can("delete", this.page.model);
    },
  },
  watch: {
    options: {
      handler() {
        helpFunctions.setLocalStorageValue(this.page.name + "_options", this.options);
        if (this.all_records && this.all_records.length > 0) {
          this.showRecords();
        } else {
          this.load();
        }
      },
      deep: true,
    },
    search: {
      handler() {
        helpFunctions.setLocalStorageValue(this.page.name + "_search", this.search);
        this.options.page = 1;
      },
    },
  },
};
</script>

<style>
.v-checkbox {
  display: flex;
}
:deep(div.v-table__wrapper) {
  overflow: unset;
}
.custom-disabled {
  pointer-events: none;
  opacity: 0.6;
}
.v-card {
  position: static !important;
  overflow: visible !important;
}
</style>